import React from 'react'
import { Text, Box, Flex } from 'rebass'
import TechSvg from '../svg/techSvg'
import { useInView } from 'react-intersection-observer'
import { SlideIn, FadeInUp, Stagger } from '../components/Animations'

const Tech = () => {
  const keywords = [
    'React',
    'React-Native',
    'Serverless Stack',
    'PostgreSQL',
    'Amazon AWS',
  ]

  const [ref, inView] = useInView({
    threshold: 0.7,
  })

  return (
    <Stagger inView={inView}>
      <Box my={6} name="Tech" ref={ref}>
        <Flex
          flexWrap="wrap"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} px={2}>
            <FadeInUp>
              <Text variant="heading">
                Top notch tech for building the best applications.
              </Text>
            </FadeInUp>
            <Box>
              {keywords.map(keyword => (
                <FadeInUp>
                  <Text key={keyword} variant="paragraph" my={3}>
                    {keyword}
                  </Text>
                </FadeInUp>
              ))}
            </Box>
          </Box>
          <Box
            width={[2 / 3, 1 / 2, 1 / 2, 1 / 2]}
            px={2}
            textAlign="center"
            mx="auto"
          >
            <SlideIn direction={200}>
              <TechSvg />
            </SlideIn>
          </Box>
        </Flex>
      </Box>
    </Stagger>
  )
}

export default Tech
