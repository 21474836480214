import React from 'react'
import { Text, Box, Flex } from 'rebass'
import CloudServerSvg from '../svg/cloudServerSvg'
import { useInView } from 'react-intersection-observer'
import { SlideIn, FadeInUp, Stagger } from '../components/Animations'

const Cloud = () => {
  const [ref, inView] = useInView({
    threshold: 0.7,
  })

  return (
    <Stagger inView={inView}>
      <Box my={6} ref={ref}>
        <Flex
          flexWrap="wrap"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width={[2 / 3, 1 / 2, 1 / 2, 1 / 2]}
            px={2}
            textAlign="center"
            mx="auto"
          >
            <SlideIn direction={-200}>
              <CloudServerSvg />
            </SlideIn>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2, 1 / 2]}>
            <FadeInUp>
              <Text variant="heading" as="h1">
                Running applications on cloud brings efficiency.
              </Text>
            </FadeInUp>
            <FadeInUp>
              <Text variant="paragraph" my={3} as="h2">
                When running your server on cloud you will only pay for what you
                use. Storage space and server performance can be easily
                increased when needed. Your business is safe from data losses
                and physical hardware expenses.
              </Text>
            </FadeInUp>
          </Box>
        </Flex>
      </Box>
    </Stagger>
  )
}

export default Cloud
