import React, { useState, useEffect } from 'react'
import { Box, Flex, Text } from 'rebass'
import ReactTyped from 'react-typed'
import Fade from 'react-reveal/Fade'

const Cookies = () => {
  const [show, setShow] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [showLink, setShowLink] = useState(false)

  const handleCookies = () => {
    if (document.cookie.includes('cookies=accepted')) {
      setShow(false)
    } else {
      setShow(true)
    }
  }

  const setCookies = () => {
    const d = new Date()
    const year = d.getFullYear()
    const month = d.getMonth()
    const day = d.getDate()
    const expiringDate = new Date(year, month + 6, day)
    document.cookie = `cookies=accepted;expires=${expiringDate.toUTCString()};`
    setShow(false)
  }

  const cookiesTerms = () => {
    setTimeout(() => {
      setShowTerms(true)
      setTimeout(() => {
        setShowLink(true)
      }, 700)
    }, 700)
  }

  useEffect(() => {
    handleCookies()
  }, [])

  return (
    <Box width={['100%', '360px']} variant="terminalOuterBox">
      <Fade bottom when={show}>
        <Box variant="terminalInnerBox">
          <Box variant="terminalHeader">
            <Flex py={2} px={1} alignItems="center">
              <Box
                variant="terminalButton"
                sx={{
                  backgroundColor: '#fe656a',
                }}
              />
              <Box
                variant="terminalButton"
                sx={{
                  backgroundColor: '#ffd404',
                }}
              />
              <Box
                variant="terminalButton"
                sx={{
                  backgroundColor: '#00ff58',
                }}
              />
            </Flex>
          </Box>
          <Box variant="terminalTextBox">
            <Text
              sx={{
                color: 'purple',
              }}
            >
              cookie@kodelabs:~${' '}
              <ReactTyped
                strings={['show cookies']}
                typeSpeed={80}
                showCursor={false}
                style={{ color: 'white' }}
                startDelay={1000}
                onComplete={cookiesTerms}
              />
            </Text>

            {showTerms && (
              <>
                <Text>
                  <br />
                  Cookies are fantastic, here at Kode Labs we do not only love
                  to eat them, but we also use them to provide more user
                  friendly experience on our website. By browsing this website
                  you accept the terms of our cookies policy. 🍪
                </Text>
                {showLink && (
                  <Text
                    my={4}
                    textAlign="center"
                    sx={{
                      '&:hover': { color: 'purple', cursor: 'pointer' },
                    }}
                    onClick={setCookies}
                  >
                    [ OK, cookies are really great! ]
                  </Text>
                )}
              </>
            )}
          </Box>
        </Box>
      </Fade>
    </Box>
  )
}
export default Cookies
