import React from 'react'
import { Text, Box, Flex } from 'rebass'
import GlobalConnections from '../svg/global_connections.js'
import { useInView } from 'react-intersection-observer'
import { SlideIn, FadeInUp, Stagger } from '../components/Animations'

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.7,
  })

  return (
    <Stagger inView={inView}>
      <Box my={6} ref={ref}>
        <Flex
          flexWrap="wrap"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width={[2 / 3, 1 / 2, 1 / 2, 1 / 2]}
            px={2}
            textAlign="center"
            mx="auto"
          >
            <SlideIn direction={-200}>
              <GlobalConnections />
            </SlideIn>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2, 1 / 2]}>
            <FadeInUp>
              <Text variant="heading">
                Our goal is to be the forerunner of the web.
              </Text>
            </FadeInUp>
            <Box>
              <FadeInUp>
                <Text variant="paragraph" my={3}>
                  We want to serve our customers the most efficient way to
                  improve their business. We have chosen the leading tools from
                  the field of tech to build modern web and mobile applications.
                </Text>
              </FadeInUp>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Stagger>
  )
}

export default About
