import React from 'react'
import { Box, Flex, Text, Link } from 'rebass'
import { useInView } from 'react-intersection-observer'
import { FadeInUp, Stagger } from '../components/Animations'

const Contact = () => {
  const [ref, inView] = useInView({
    threshold: 0.7,
  })

  return (
    <Stagger inView={inView}>
      <Box my={6} name="Contact" ref={ref}>
        <Flex>
          <Box width={[1, 2 / 3]}>
            <FadeInUp>
              <Text variant="heading">Talk to us.</Text>
              <Text variant="paragraph">We are ready when you are.</Text>
            </FadeInUp>
            <FadeInUp>
              <Text variant="smallTextPurple">BUSINESS INQUIRIES</Text>
              <Text variant="largeEmailText">
                <Link
                  href="mailto:business@kodelabs.io"
                  variant="emailLink"
                  title="Email"
                >
                  business@kodelabs.io
                </Link>
              </Text>
            </FadeInUp>
            <FadeInUp>
              <Text variant="smallTextPurple">SAY HI</Text>
              <Text variant="largeEmailText">
                <Link
                  href="mailto:hello@kodelabs.io"
                  variant="emailLink"
                  title="Email"
                >
                  hello@kodelabs.io
                </Link>
              </Text>
            </FadeInUp>
          </Box>
        </Flex>
      </Box>
    </Stagger>
  )
}

export default Contact
