import React from 'react'
import { Box, Text } from 'rebass'
import ReactTyped from 'react-typed'

const Landing = () => {
  return (
    <Box
      name="Home"
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text variant="landing">
        <ReactTyped
          strings={['We are building a better internet every day.']}
          typeSpeed={80}
          cursorChar="_"
        />
      </Text>
    </Box>
  )
}

export default Landing
