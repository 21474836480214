import React from 'react'
import { motion } from 'framer-motion'

const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  initial: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
}

const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.3 },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
}

export const Stagger = props => {
  return (
    <motion.div
      animate={props.inView ? 'animate' : 'initial'}
      variants={stagger}
    >
      {props.children}
    </motion.div>
  )
}

export const FadeInUp = props => {
  return <motion.div variants={fadeInUp}>{props.children}</motion.div>
}

export const SlideIn = props => {
  return (
    <motion.div
      variants={{
        initial: {
          x: props.direction,
          opacity: 0,
        },
        animate: {
          x: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            delay: 0.2,
          },
        },
      }}
    >
      {props.children}
    </motion.div>
  )
}
