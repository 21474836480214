import React from 'react'
import { Flex, Box, Text, Link } from 'rebass'
import * as Unicons from '@iconscout/react-unicons'

const Footer = () => {
  return (
    <Box py={3} mt={5} variant="footer">
      <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
        <Box width={[1, 1, 1 / 3, 1 / 3, 1 / 3]} textAlign="center" my={3}>
          <Text>© Kode Labs Oy {new Date().getFullYear()}</Text>
        </Box>
        <Box width={[1, 1, 1 / 3, 1 / 3, 1 / 3]} textAlign="center" my={3}>
          <Flex justifyContent="center" flexDirection="row">
            <Link
              mx={3}
              href="https://www.instagram.com/kodelabs.io"
              target="_blank"
              rel="noopener noreferrer"
              title="Instagram"
              color="text"
              sx={{
                '&:hover': { color: 'green', transition: '0.3s ease-in-out' },
              }}
            >
              <Unicons.UilInstagram size="30" />
            </Link>
            <Link
              mx={3}
              href="https://www.twitter.com/wearekode"
              target="_blank"
              rel="noopener noreferrer"
              title="Twitter"
              color="text"
              sx={{
                '&:hover': { color: 'purple', transition: '0.3s ease-in-out' },
              }}
            >
              <Unicons.UilTwitterAlt size="30" />
            </Link>
            <Link
              mx={3}
              href="https://www.linkedin.com/company/52189188/"
              target="_blank"
              rel="noopener noreferrer"
              title="LinkedIn"
              color="text"
              sx={{
                '&:hover': {
                  color: 'darkBlue',
                  transition: '0.3s ease-in-out',
                },
              }}
            >
              <Unicons.UilLinkedinAlt size="30" />
            </Link>
          </Flex>
        </Box>
        <Box
          width={[1, 1, 1 / 3, 1 / 3, 1 / 3]}
          sx={{ textAlign: 'center' }}
          my={3}
        >
          <Text>
            <Link
              href="mailto:hello@kodelabs.io"
              variant="emailLink"
              title="Email"
            >
              hello@kodelabs.io
            </Link>
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default Footer
