import React from 'react'
import { Box, Text, Flex, Card, Link, Image } from 'rebass'
import ImageOfRasmus from '../images/rasmus.jpg'
import ImageOfJoonatan from '../images/joonatan.jpg'
import { useInView } from 'react-intersection-observer'
import { FadeInUp, Stagger } from '../components/Animations'

const People = () => {
  const [ref, inView] = useInView({
    threshold: 0.7,
  })

  const staff = [
    {
      id: 1,
      image: `${ImageOfRasmus}`,
      name: 'Rasmus',
      position: 'Co-Founder / Developer',
      email: 'rasmus@kodelabs.io',
    },
    {
      id: 2,
      image: `${ImageOfJoonatan}`,
      name: 'Joonatan',
      position: 'Co-Founder / Developer',
      email: 'joonatan@kodelabs.io',
    },
  ]

  return (
    <Stagger inView={inView}>
      <Box my={6} name="People" ref={ref}>
        <FadeInUp>
          <Text variant="heading" textAlign="center">
            Masterminds behind this all.
          </Text>
        </FadeInUp>
        <Flex my={4} flexWrap="wrap" justifyContent="center">
          {staff.map(employee => (
            <FadeInUp>
              <Card mx={4} mt={2} key={employee.id} textAlign="center">
                <Image
                  src={employee.image}
                  variant="staffImage"
                  alt={employee.name}
                />
                <Text variant="staffName">{employee.name}</Text>
                <Text variant="smallText">{employee.position}</Text>
                <Text variant="smallText">
                  <Link
                    variant="emailLink"
                    href={`mailto:${employee.email}`}
                    title="Email"
                  >
                    {employee.email}
                  </Link>
                </Text>
              </Card>
            </FadeInUp>
          ))}
        </Flex>
      </Box>
    </Stagger>
  )
}

export default People
