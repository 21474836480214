import React from 'react'

const CloudServerSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 209 209"
      width="85%"
    >
      <g fillRule="nonzero">
        <g fill="#b7c5fd">
          <path d="M9.251 147.803c.025 8.496 3.021 14.481 7.859 17.293l22.425 13.024c-4.839-2.812-7.843-8.789-7.869-17.293-.041-16.071 10.572-35.44 24.199-45.275-.042-17.109 11.986-37.951 26.869-46.539 5.951-3.44 11.459-4.403 15.92-3.223 6.78-17.778 19.687-34.586 34.52-43.149 11.04-6.37 21.043-6.964 28.266-2.771L139.025 6.846c-7.224-4.193-17.227-3.607-28.267 2.771-14.832 8.563-27.739 25.37-34.519 43.149-4.47-1.18-9.97-.226-15.929 3.214-14.883 8.597-26.911 29.43-26.861 46.539-13.627 9.844-24.24 29.213-24.198 45.284z"></path>
          <path d="M150.534 29.806l22.416 13.033c6.83-3.055 12.957-3.005 17.561-.327L168.086 29.48c-4.595-2.671-10.722-2.721-17.552.326z"></path>
        </g>
        <path
          fill="#d9ddf6"
          d="M133.175 22.645c-14.83 8.562-27.737 25.369-34.517 43.147-4.467-1.178-9.973-.22-15.926 3.217-14.884 8.593-26.912 29.432-26.863 46.541-13.633 9.839-24.246 29.209-24.201 45.28.049 17.102 12.15 24.011 27.039 15.415l111.073-64.129c16.236-9.373 29.356-32.098 29.303-50.76-.049-17.336-11.473-25.066-26.132-18.521-1.212-23.357-18.581-32.427-39.776-20.19z"
        ></path>
        <g>
          <g fill="#6bb5fb">
            <path d="M121.488 185.942l61.048-35.251c.801-.46 1.522-.501 2.023-.2l-42.59-24.751c-.5-.3-1.221-.261-2.022.201l-61.048 35.25c-.662.381-1.261.999-1.742 1.715l42.589 24.751c.481-.716 1.081-1.334 1.742-1.715z"></path>
            <path d="M76.115 177.414c0 .881.32 1.523.821 1.803l42.589 24.751c-.501-.281-.821-.922-.821-1.822l-.04-11.297c0-1.051.427-2.216 1.082-3.192l-42.589-24.751c-.655.976-1.082 2.141-1.082 3.192l.04 11.316z"></path>
          </g>
          <path
            fill="#4466fb"
            d="M182.543 150.692c1.573-.908 2.843-.184 2.848 1.623l.032 11.315c.005 1.793-1.257 3.993-2.83 4.901l-61.048 35.247c-1.573.908-2.843.169-2.848-1.624l-.032-11.314c-.006-1.808 1.256-3.993 2.829-4.901l61.049-35.247z"
          ></path>
          <path
            fill="#6bb5fb"
            d="M151.695 175.633l-28.038 16.203v4.517l28.038-16.227v-4.493z"
          ></path>
          <path
            fill="#b7c5fd"
            d="M179.214 158.863c1.087-.627 1.973-.123 1.976 1.111.001.483-.13 1.006-.353 1.5-.355.785-.943 1.501-1.61 1.886-.659.381-1.246.343-1.603-.031-.225-.236-.358-.605-.36-1.088-.003-1.234.876-2.758 1.95-3.378m-6.048 3.492c1.087-.627 1.959-.115 1.963 1.119.001.483-.128 1.004-.35 1.497-.35.783-.934 1.496-1.6 1.881-.659.381-1.247.343-1.603-.031-.225-.236-.359-.605-.36-1.088-.004-1.234.876-2.758 1.95-3.378m-6.049 3.492c1.087-.627 1.96-.115 1.963 1.119.001.483-.128 1.004-.349 1.497-.351.783-.934 1.496-1.601 1.881-.659.381-1.246.343-1.603-.031-.225-.236-.359-.605-.36-1.088-.003-1.234.876-2.757 1.95-3.378"
          ></path>
          <g>
            <path
              fill="#3955d1"
              d="M119.093 167.939l-37.935-21.831v14.495l35.899 20.688a3.685 3.685 0 003.696-.005l58.287-33.893.187-14.384-60.134 34.93z"
            ></path>
            <g fill="#6bb5fb">
              <path d="M121.488 158.842l61.048-35.251c.801-.46 1.522-.501 2.023-.199l-42.59-24.751c-.5-.301-1.221-.262-2.022.2l-61.048 35.25c-.662.381-1.261.999-1.742 1.715l42.589 24.751c.481-.716 1.081-1.334 1.742-1.715z"></path>
              <path d="M76.115 150.315c0 .881.32 1.522.821 1.802l42.589 24.751c-.501-.281-.821-.921-.821-1.822l-.04-11.297c0-1.051.427-2.216 1.082-3.192l-42.589-24.751c-.655.976-1.082 2.141-1.082 3.192l.04 11.317z"></path>
            </g>
            <path
              fill="#4466fb"
              d="M182.543 123.593c1.573-.909 2.843-.185 2.848 1.623l.032 11.314c.005 1.793-1.257 3.993-2.83 4.902l-61.048 35.246c-1.573.908-2.843.17-2.848-1.623l-.032-11.315c-.006-1.807 1.256-3.993 2.829-4.901l61.049-35.246z"
            ></path>
            <path
              fill="#6bb5fb"
              d="M151.695 148.533l-28.038 16.204v4.516l28.038-16.227v-4.493z"
            ></path>
            <path
              fill="#b7c5fd"
              d="M179.214 131.764c1.087-.628 1.973-.123 1.976 1.111.001.483-.13 1.005-.353 1.5-.355.784-.943 1.501-1.61 1.886-.659.38-1.246.342-1.603-.031-.225-.236-.358-.606-.36-1.089-.003-1.234.876-2.757 1.95-3.377m-6.048 3.492c1.087-.628 1.959-.116 1.963 1.118.001.483-.128 1.004-.35 1.498-.35.782-.934 1.496-1.6 1.881-.659.38-1.247.342-1.603-.031-.225-.236-.359-.606-.36-1.089-.004-1.234.876-2.757 1.95-3.377m-6.049 3.492c1.087-.628 1.96-.116 1.963 1.118.001.483-.128 1.004-.349 1.498-.351.782-.934 1.496-1.601 1.881-.659.38-1.246.342-1.603-.031-.225-.236-.359-.605-.36-1.089-.003-1.234.876-2.757 1.95-3.377"
            ></path>
          </g>
          <g>
            <path
              fill="#3955d1"
              d="M119.093 140.839l-37.935-21.831v14.495l35.899 20.689a3.687 3.687 0 003.696-.006l58.287-33.893.187-14.383-60.134 34.929z"
            ></path>
            <g fill="#6bb5fb">
              <path d="M121.488 131.742l61.048-35.25c.801-.46 1.522-.501 2.023-.2l-42.59-24.751c-.5-.3-1.221-.261-2.022.2l-61.048 35.25c-.662.382-1.261 1-1.742 1.716l42.589 24.751c.481-.716 1.081-1.334 1.742-1.716zM76.115 123.215c0 .881.32 1.523.821 1.802l42.589 24.751c-.501-.28-.821-.921-.821-1.822l-.04-11.296c0-1.051.427-2.216 1.082-3.192l-42.589-24.751c-.655.976-1.082 2.141-1.082 3.192l.04 11.316z"></path>
            </g>
            <path
              fill="#4466fb"
              d="M182.543 96.493c1.573-.908 2.843-.184 2.848 1.623l.032 11.315c.005 1.793-1.257 3.993-2.83 4.901l-61.048 35.246c-1.573.908-2.843.17-2.848-1.623l-.032-11.315c-.006-1.807 1.256-3.993 2.829-4.901l61.049-35.246z"
            ></path>
            <path
              fill="#6bb5fb"
              d="M152.044 123.034l-28.038 16.204v4.516l28.038-16.227v-4.493z"
            ></path>
            <path
              fill="#b7c5fd"
              d="M179.214 104.664c1.087-.627 1.973-.123 1.976 1.111.001.483-.13 1.005-.353 1.5-.355.785-.943 1.501-1.61 1.886-.659.38-1.246.343-1.603-.031-.225-.236-.358-.605-.36-1.088-.003-1.234.876-2.758 1.95-3.378m-6.048 3.492c1.087-.627 1.959-.115 1.963 1.119.001.483-.128 1.004-.35 1.497-.35.783-.934 1.496-1.6 1.881-.659.38-1.247.343-1.603-.031-.225-.236-.359-.605-.36-1.088-.004-1.234.876-2.758 1.95-3.378m-6.049 3.492c1.087-.627 1.96-.115 1.963 1.119.001.483-.128 1.004-.349 1.497-.351.783-.934 1.496-1.601 1.881-.659.381-1.246.343-1.603-.031-.225-.236-.359-.605-.36-1.088-.003-1.234.876-2.758 1.95-3.378"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CloudServerSvg
