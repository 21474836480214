import React from 'react'
import { Box, Text, Flex, Image } from 'rebass'
import SkodaLogo from '../images/skoda_logo.png'
import { useInView } from 'react-intersection-observer'
import { SlideIn, FadeInUp, Stagger } from '../components/Animations'

const Projects = () => {
  const [ref, inView] = useInView({
    threshold: 0.7,
  })

  return (
    <Stagger inView={inView}>
      <Box my={6} name="Projects" ref={ref}>
        <FadeInUp>
          <Text variant="heading" width={[1, 3 / 4]}>
            Sneak peak of our projects and customers.
          </Text>
        </FadeInUp>
        <Flex
          my={4}
          mx={4}
          flexWrap="wrap"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width={[2 / 3, 1 / 2, 1 / 2]}>
            <FadeInUp>
              <Text variant="smallTextPurple">CUSTOMER</Text>
              <Text variant="boldText">Skoda Transtech</Text>
            </FadeInUp>
            <FadeInUp>
              <Text variant="smallTextPurple">PROJECT</Text>
              <Text variant="boldText">Supplier Portal</Text>
            </FadeInUp>
            <FadeInUp>
              <Text variant="smallTextPurple">TYPE</Text>
              <Text variant="boldText">Web Application</Text>
            </FadeInUp>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} mt={[4]}>
            <SlideIn direction={200}>
              <Image width="100%" src={SkodaLogo} alt="SkodaLogo" />
            </SlideIn>
          </Box>
        </Flex>
      </Box>
    </Stagger>
  )
}

export default Projects
