import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Landing from '../sections/Landing'
import Cookies from '../components/Cookies'
import About from '../sections/About'
import Tech from '../sections/Tech'
import Cloud from '../sections/Cloud'
import Projects from '../sections/Projects'
import People from '../sections/People'
import Contact from '../sections/Contact'
import Footer from '../components/Footer'
import kodeLogo from '../images/kode-labs-logo.jpg'

const IndexPage = () => (
  <>
    <Layout>
      <SEO
        title="Kode Labs"
        keywords={[
          'software',
          'application',
          'web',
          'kode',
          'labs',
          'mobile',
          'service',
          'cloud',
          'efficiency',
        ]}
        image={kodeLogo}
      />
      <Cookies />
      <Landing />
      <About />
      <Tech />
      <Cloud />
      <Projects />
      <People />
      <Contact />
      <Footer />
    </Layout>
  </>
)

export default IndexPage
