import React from 'react'

const TechSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 209 209"
      width="90%"
    >
      <g fillRule="nonzero">
        <path
          fill="#2e4277"
          d="M93.578 154.561l-.022 4.886c-.001.843-.556 1.685-1.663 2.328l-24.86 14.448c-2.229 1.294-5.853 1.295-8.096 0l-25.023-14.447c-1.129-.652-1.693-1.507-1.691-2.361l.022-4.881c0 .851.563 1.705 1.689 2.355l25.023 14.448c2.244 1.294 5.868 1.294 8.097-.001l24.86-14.447c1.107-.644 1.662-1.486 1.664-2.328z"
        ></path>
        <path
          fill="#384e85"
          d="M91.888 152.199c2.243 1.295 2.255 3.394.026 4.689l-24.861 14.448c-2.228 1.295-5.852 1.295-8.095 0l-25.024-14.447c-2.243-1.295-2.254-3.394-.026-4.689l24.861-14.448c2.229-1.295 5.853-1.295 8.095 0l25.024 14.447z"
        ></path>
        <path
          fill="#2e4277"
          d="M82.165 153.507c.992.573.996 1.501.011 2.074l-17.412 10.118c-.986.573-2.588.574-3.58.001l-17.526-10.118c-.992-.573-.997-1.502-.012-2.075l17.412-10.118c.986-.573 2.589-.573 3.581 0l17.526 10.118z"
        ></path>
        <path
          fill="#3955d1"
          d="M43.119 13.95l-.007 13.476-10.879 6.323.007-13.477 10.879-6.322z"
        ></path>
        <path
          fill="#4466fb"
          d="M32.24 20.272l-.007 13.477-10.961-6.328.007-13.477 10.961 6.328z"
        ></path>
        <path
          fill="#6bb5fb"
          d="M43.119 13.95L32.24 20.272l-10.961-6.328 10.88-6.322 10.96 6.328z"
        ></path>
        <path
          fill="#384e85"
          d="M61.058 108.03l.265.327 1.577-.869-6.399-7.834 4.257-.539.163-.091.268.327 1.574-.869-6.423-7.869 4.447-.563.162-.091.265.327 1.577-.869-6.426-7.869 4.447-.563.163-.091.268.327 1.574-.869-6.423-7.869 4.447-.563.163-.091.265.327 1.577-.869-6.426-7.869 4.446-.563.163-.091.268.327 1.574-.868-6.423-7.869 4.447-.564.163-.09.265.327 1.577-.869-6.86-8.399-1.574.866 6.148 7.533-4.878.617-1.573.869 1.12-.142-.69.378 5.996 7.343-4.878.617-1.577.869 1.12-.142-.686.378 5.992 7.342-4.878.618-1.574.869 1.121-.142-.69.378 5.995 7.342-4.877.618-1.578.869 1.121-.142-.686.378 5.992 7.342-4.878.618-1.574.869 1.12-.142-.689.378 5.995 7.342-4.721.6-1.574.866.383-.049-.102.056 6.148 7.533-4.878.618-1.573.869 1.12-.142-.69.378 5.996 7.342-4.878.618-1.577.869 1.12-.143-.686.379 5.992 7.342-4.878.618-1.574.869 1.121-.143-.69.379 5.995 7.342-4.877.618-1.578.869 1.121-.143-.686.379 5.992 7.342-4.878.618-1.574.869 1.12-.143-.689.379 5.995 7.342-4.721.599-1.574.866 6.573-.832.163-.091.268.327 1.574-.869-6.423-7.869 4.447-.563.162-.091.265.327 1.577-.869-6.426-7.869 4.447-.563.163-.091.268.327 1.574-.869-6.423-7.869 4.447-.563.163-.091.265.327 1.577-.869-6.426-7.869 4.446-.563.163-.091.268.327 1.574-.869-6.423-7.869 4.447-.563.163-.091z"
        ></path>
        <path
          fill="#384e85"
          d="M70.468 145.234l-1.123-.142.692.379-5.998 7.342 4.722.599 1.576.865-6.573-.832-.164-.091-.268.327-1.576-.868 6.426-7.87-4.446-.564-.164-.091-.264.328-1.58-.868 6.43-7.868-4.45-.563-.16-.091-.268.327-1.576-.871 6.422-7.867-4.446-.564-.164-.091-.264.328-1.576-.868 6.426-7.871-4.446-.563-.164-.091-.268.327-1.576-.868 6.426-7.871-4.446-.563-.164-.091-.264.327-1.58-.868 6.402-7.835-4.258-.537-.164-.091-.268.327-1.576-.871 6.426-7.867-4.446-.564-.164-.09-.264.327-1.58-.868 6.43-7.871-4.45-.563-.16-.091-.268.327-1.576-.868 6.422-7.87-4.446-.564-.164-.091-.264.327-1.576-.868 6.426-7.87-4.446-.56-.164-.091-.268.324-1.576-.868 6.426-7.867-4.446-.564-.164-.091-.264.327-1.58-.871 6.861-8.398 1.576.868-6.15 7.53 4.878.619 1.572.868-1.12-.143.692.379-5.998 7.343 4.878.618 1.58.868-1.124-.142.688.379-5.994 7.342 4.878.619 1.576.868-1.12-.142.688.378-5.994 7.343 4.878.615 1.576.872-1.12-.143.688.379-5.994 7.343 4.878.615 1.575.871-1.123-.142.692.375-5.998 7.343 4.722.599 1.576.868-.38-.048.1.055-6.15 7.533 4.878.619 1.572.868-1.12-.143.692.379-5.998 7.343 4.878.615 1.58.872-1.124-.143.688.379-5.994 7.343 4.878.615 1.576.871-1.12-.142.688.375-5.994 7.343 4.878.619 1.576.868-1.12-.143.688.379-5.994 7.343 4.878.618 1.575.868z"
        ></path>
        <g fill="#1b2a4f">
          <path d="M61.325 108.356l-6.729.855 6.701 8.211-6.729.855 6.701 8.207-6.729.859 6.701 8.207-6.729.858 6.705 8.208-6.729.855 6.701 8.21-6.889.875-.116-.276 6.573-.835-6.705-8.211 6.733-.855-6.705-8.21 6.733-.852-6.705-8.211 6.733-.855-6.705-8.21 6.729-.855-6.701-8.211 6.729-.855-6.457-7.913-.136.017-.116-.276 6.573-.832-6.705-8.211 6.733-.855-6.705-8.21 6.733-.855-6.705-8.211 6.733-.855-6.705-8.211 6.729-.855-6.701-8.207 6.729-.855-6.545-8.019.116-.146 6.861 8.398-6.729.859 6.701 8.207-6.729.855 6.701 8.21-6.729.856 6.701 8.21-6.729.855 6.705 8.207-6.729.859 6.701 8.207-6.545.829 6.681 8.178zM70.468 145.234l-6.704 8.211 6.573.835-.116.276-6.889-.875 6.705-8.21-6.733-.855 6.705-8.208-6.729-.858 6.701-8.207-6.729-.859 6.701-8.207-6.729-.855 6.701-8.211-6.729-.855 6.681-8.178-6.545-.829 6.705-8.207-6.733-.859 6.705-8.207-6.729-.855 6.701-8.211-6.729-.855 6.701-8.21-6.729-.855 6.701-8.207-6.729-.859 6.861-8.398.12.146-6.549 8.019 6.733.855-6.705 8.207 6.729.855-6.701 8.211 6.728.855-6.705 8.211 6.733.855-6.705 8.21 6.733.855-6.705 8.211 6.574.832-.116.276-.132-.017-6.461 7.913 6.733.855-6.705 8.211 6.729.855-6.701 8.21 6.728.855-6.705 8.211 6.733.852-6.705 8.21 6.733.855z"></path>
        </g>
        <path
          fill="#384e85"
          d="M60.985 98.491l1.276-.6 1.278.598-1.275.6-1.279-.598zM68.553 94.929l1.275-.6 1.279.598-1.276.6-1.278-.598z"
        ></path>
        <path
          fill="#2e4277"
          d="M69.83 101.133v55.965l-1.288-.596v-55.965l1.288.596z"
        ></path>
        <path
          fill="#384e85"
          d="M55.968 94.929l-1.275-.6-1.279.598 1.276.6 1.278-.598z"
        ></path>
        <g>
          <path
            fill="#1b2a4f"
            d="M63.545 44.157l-.001 116.818-1.287.621V44.753l1.287-.596z"
          ></path>
          <path
            fill="#384e85"
            d="M60.985 44.161l1.276-.601 1.278.598-1.275.601-1.279-.598z"
          ></path>
          <path
            fill="#2e4277"
            d="M62.257 44.753v116.843l-1.288-.621L61 44.157l1.257.596z"
          ></path>
          <g>
            <path
              fill="#1b2a4f"
              d="M71.118 40.606v115.896l-1.288.596V41.202l1.288-.596z"
            ></path>
            <path
              fill="#384e85"
              d="M68.553 40.598l1.275-.6 1.279.598-1.276.6-1.278-.598z"
            ></path>
            <path
              fill="#2e4277"
              d="M69.83 41.202v59.931l-1.288-.596V40.606l1.288.596z"
            ></path>
          </g>
          <g>
            <path
              fill="#1b2a4f"
              d="M54.684 41.202v115.896l-1.257-.596V40.606l1.257.596z"
            ></path>
            <path
              fill="#384e85"
              d="M55.968 40.598l-1.275-.6-1.279.598 1.276.6 1.278-.598z"
            ></path>
            <path
              fill="#2e4277"
              d="M55.972 40.606v115.896l-1.288.596V41.202l1.288-.596z"
            ></path>
          </g>
        </g>
        <g>
          <path
            fill="#8ca1fd"
            d="M62.311 38.319l-.009 11.825 13.2-7.671.009-11.825-13.2 7.671z"
          ></path>
          <path
            fill="#b7c5fd"
            d="M62.311 38.319l-.009 11.825-13.299-7.678.008-11.825 13.3 7.678z"
          ></path>
          <path
            fill="#d9ddf6"
            d="M75.511 30.648l-13.2 7.671-13.3-7.678 13.201-7.672 13.299 7.679z"
          ></path>
        </g>
        <g>
          <path
            fill="#b7c5fd"
            d="M96.315 63.473c.507-.295 1.208-.254 1.981.193l87.58 50.564c1.543.891 2.787 3.045 2.782 4.811l-.15 72.406c-.002.876-.312 1.493-.812 1.784l-3.649 2.121c.5-.291.809-.908.812-1.784l.149-72.406c.005-1.766-1.239-3.921-2.781-4.811l-87.58-50.564c-.773-.447-1.474-.488-1.981-.194l3.649-2.12z"
          ></path>
          <path
            fill="#9aadfd"
            d="M94.647 65.787c-1.537-.888-2.789-.174-2.794 1.591l-.149 72.407c-.005 1.765 1.239 3.92 2.775 4.807l87.58 50.564c1.543.891 2.795.177 2.8-1.588l.149-72.406c.005-1.766-1.239-3.921-2.781-4.811l-87.58-50.564z"
          ></path>
          <path
            fill="#d9ddf6"
            d="M95.31 76.215l-.123 64.779 86.187 49.761.124-64.78-86.188-49.76z"
          ></path>
          <g fill="#fff">
            <path d="M105.908 76.168c-.789-.456-1.431-.089-1.434.817-.003.907.636 2.008 1.425 2.464.785.453 1.427.091 1.43-.815.003-.907-.636-2.013-1.421-2.466zM96.744 70.878c-.789-.455-1.431-.089-1.434.818-.002.906.636 2.007 1.425 2.463.785.453 1.428.092 1.43-.815.003-.907-.636-2.012-1.421-2.466zM101.326 73.523c-.789-.456-1.431-.09-1.434.817-.002.907.636 2.008 1.425 2.463.789.456 1.427.092 1.43-.815.003-.906-.632-2.01-1.421-2.465z"></path>
          </g>
        </g>
        <g>
          <g fill="#384e85">
            <path d="M135.837 105.121l1.042.605c.652-.204 1.235-.157 1.691.109l-1.043-.606c-.456-.265-1.038-.313-1.69-.108zM135.307 108.341l-.025-9.719 1.577-.911-1.042-.605-1.577.91.024 9.719 1.043.606z"></path>
          </g>
          <path
            fill="#d9ddf6"
            d="M138.014 108.973c-.002-.599-.214-1.021-.557-1.22l-1.042-.606c.342.199.555.621.556 1.22.003.793-.383 1.683-.932 2.387.304.26.581.564.804.924.689-.73 1.174-1.794 1.171-2.705z"
          ></path>
          <path
            fill="#1b2a4f"
            d="M139.586 108.065c-.005-1.893-1.162-2.823-2.707-2.338l-.02-8.016-1.577.911.025 9.719.788-.456c1.053-.608 1.916-.122 1.919 1.088.003.911-.482 1.975-1.171 2.705.273.441.445.919.529 1.395 1.297-1.332 2.219-3.311 2.214-5.008z"
          ></path>
          <path
            fill="#d9ddf6"
            d="M138.292 113.979l-1.376.806c-.334.197-.806.158-1.317-.137-1.023-.59-1.848-2.025-1.829-3.205 0-.57.197-.963.531-1.16l1.376-.806c-.334.197-.53.59-.53 1.16 0 1.18.825 2.615 1.848 3.205.511.295.963.334 1.297.137z"
          ></path>
          <path
            fill="#6bb5fb"
            d="M162.96 125.314l-.039 17.419c-.02.884-.315 1.514-.826 1.789l-1.376.806c.492-.276.806-.905.806-1.789l.059-17.419c0-1.769-1.239-3.932-2.792-4.836l-46.348-26.759c-.766-.432-1.474-.472-1.985-.177l1.396-.806c.511-.295 1.199-.255 1.966.177l46.348 26.759c1.553.904 2.791 3.067 2.791 4.836z"
          ></path>
          <path
            fill="#4466fb"
            d="M158.786 121.29c1.556.899 2.795 3.059 2.79 4.833l-.05 17.409c-.005 1.788-1.251 2.499-2.808 1.6l-46.344-26.757c-1.532-.884-2.783-3.037-2.778-4.825l.049-17.409c.005-1.774 1.265-2.492 2.796-1.608l46.345 26.757zm-23.186-6.639c1.03.594 1.849.099 1.852-1.069.003-1.168-.81-2.577-1.84-3.171-1.016-.587-1.847-.128-1.85 1.041-.004 1.168.822 2.612 1.838 3.199"
          ></path>
          <path
            fill="#384e85"
            d="M136.039 110.754c-.29.371-.61.705-.974.915-.277.16-.544.238-.789.25l.038-.038-1.042-.606-1.472 1.491c.177.432.448.75.787.947l1.042.606c-.338-.197-.609-.515-.786-.947l1.302-1.32.602.35c.354.206.844.171 1.361-.127.261-.151.509-.356.735-.597-.222-.36-.5-.664-.804-.924z"
          ></path>
          <path
            fill="#1b2a4f"
            d="M136.108 112.275c-.789.455-1.513.297-1.794-.394l-1.471 1.491c.514 1.257 1.825 1.544 3.27.71a5.937 5.937 0 001.259-1.009 3.957 3.957 0 00-.529-1.395 3.267 3.267 0 01-.735.597z"
          ></path>
          <path
            fill="#fff"
            d="M113.423 105.806l-.064 10.277 45.295 26.151.065-10.277-45.296-26.151z"
          ></path>
          <g fill="#fff">
            <path d="M157.465 124.68c-.697-.402-1.264-.079-1.266.721-.003.801.561 1.773 1.257 2.175.693.4 1.26.081 1.263-.72.002-.8-.562-1.776-1.254-2.176zM149.376 120.011c-.697-.402-1.264-.079-1.266.721-.002.801.561 1.773 1.258 2.175.693.4 1.26.081 1.262-.72.002-.8-.561-1.776-1.254-2.176zM153.42 122.345c-.696-.402-1.263-.079-1.266.721-.002.801.561 1.773 1.258 2.175.697.402 1.26.081 1.262-.72.003-.8-.557-1.774-1.254-2.176z"></path>
          </g>
        </g>
        <g>
          <path
            fill="#1b2a4f"
            d="M140.43 95.999l2.96-1.721-3.825 6.795-2.96 1.72 3.825-6.794z"
          ></path>
          <path
            fill="#1b2a4f"
            d="M137.841 89.812l2.961-1.72 2.588 6.186-2.96 1.721-2.589-6.187z"
          ></path>
          <path
            fill="#384e85"
            d="M131.324 86.05l2.961-1.721 6.517 3.763-2.961 1.72-6.517-3.762z"
          ></path>
          <path
            fill="#2e4277"
            d="M137.841 89.812l2.589 6.187-3.825 6.794-4.131-2.384-3.764-11.177 2.614-3.182 6.517 3.762zM137.181 75.826c.079.081.079.158 0 .315V87.1c0 .157-.079.311-.236.472-.472.235-1.102.235-1.495.001-.237-.162-.315-.316-.315-.473V75.907c0-.158.078-.315.315-.473.393-.235 1.023-.235 1.495 0 .236.158.315.234.236.392z"
          ></path>
        </g>
        <g>
          <path
            fill="#1b2a4f"
            d="M137.026 74.107l3.776-2.194-.011 3.866-3.776 2.194.011-3.866z"
          ></path>
          <path
            fill="#384e85"
            d="M19.687 6.361l3.776-2.194 117.339 67.746-3.776 2.194L19.687 6.361z"
          ></path>
          <path
            fill="#2e4277"
            d="M137.026 74.107l-.011 3.866L19.676 10.227l.011-3.866 117.339 67.746z"
          ></path>
        </g>
        <g>
          <path
            fill="#4466fb"
            d="M113.353 181.877v4.773c0 .439-.293.849-.849 1.2l-14.231 8.258c-1.142.644-2.987.644-4.158 0l-14.29-8.258c-.585-.351-.878-.79-.878-1.23l.029-4.773c0 .44.293.879.849 1.23l14.319 8.229c1.142.673 3.016.673 4.129.029l14.231-8.287c.586-.322.849-.732.849-1.171z"
          ></path>
          <path
            fill="#6bb5fb"
            d="M112.483 180.656c1.159.669 1.165 1.748.032 2.407l-14.228 8.268c-1.133.658-2.996.658-4.155-.011l-14.303-8.257c-1.14-.659-1.146-1.738-.013-2.397l14.228-8.268c1.133-.658 2.996-.658 4.137 0l14.302 8.258z"
          ></path>
          <path
            fill="#fff"
            d="M98.026 177.072l3.391 7.689-13.285-1.939 9.894-5.75z"
          ></path>
        </g>
        <g>
          <path
            fill="#8ca1fd"
            d="M152.786 189.452v4.773c0 .44-.293.85-.849 1.201l-14.231 8.257c-1.142.645-2.987.645-4.158 0l-14.29-8.257c-.585-.351-.878-.791-.878-1.23l.029-4.773c0 .439.293.879.849 1.23l14.319 8.228c1.142.674 3.016.674 4.129.03l14.231-8.287c.586-.322.849-.732.849-1.172z"
          ></path>
          <path
            fill="#b7c5fd"
            d="M151.916 188.231c1.159.67 1.165 1.749.032 2.407l-14.228 8.269c-1.133.658-2.996.658-4.155-.011l-14.302-8.258c-1.141-.658-1.147-1.737-.014-2.396l14.228-8.268c1.133-.658 2.996-.658 4.137 0l14.302 8.257z"
          ></path>
        </g>
        <g>
          <path
            fill="#6bb5fb"
            d="M116.006 128.505l-11.68-6.744c-1.489-.859-3.35.213-3.353 1.932l-.022 11.659a4.471 4.471 0 002.235 3.88l11.68 6.743c1.489.86 3.35-.212 3.353-1.931l.023-11.659a4.47 4.47 0 00-2.236-3.88z"
          ></path>
          <path
            fill="#4466fb"
            d="M142.247 143.655l-11.68-6.744c-1.489-.859-3.35.213-3.353 1.932l-.023 11.659c-.003 1.6.85 3.08 2.235 3.88l11.681 6.743c1.489.86 3.349-.212 3.353-1.931l.022-11.659a4.47 4.47 0 00-2.235-3.88z"
          ></path>
          <path
            fill="#3955d1"
            d="M168.487 158.805l-11.68-6.744c-1.489-.859-3.349.213-3.353 1.932l-.022 11.659a4.47 4.47 0 002.235 3.88l11.68 6.743c1.489.86 3.35-.212 3.353-1.931l.023-11.659a4.47 4.47 0 00-2.236-3.88z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default TechSvg
